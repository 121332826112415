<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/ninja-8-bit.jpg" width="200px" height="200px">
    <HelloWorld msg="<< all your base are belong to taco bell >>"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  margin-top: 60px;
}
@font-face {
  font-family: "juicy";
  font-size: 65px;
  src: local("juicy"),
   url(./fonts/04B_30__.TTF) format("truetype");
}
</style>
